html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'AvenirLTStd';
  font-display: fallback;
  src: url('./fonts/AvenirLTStd-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd';
  font-display: fallback;
  src: url('./fonts/AvenirLTStd-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd';
  font-display: fallback;
  src: url('./fonts/AvenirLTStd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd';
  font-display: fallback;
  src: url('./fonts/AvenirLTStd-Black.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  opacity: 1;
  color: #455a64;
  font-size: 13px;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  opacity: 1;
  color: #455a64;
  font-size: 13px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  opacity: 1;
  line-height: 1.6;
  color: #455a64;
  font-size: 13px;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  opacity: 1;
  color: #455a64;
  font-size: 13px;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
  outline: none;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
  outline: none;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: transparent;
  outline: none;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
  outline: none;
}

* {
  line-height: normal;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

* :invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}

* :-moz-submit-invalid {
  box-shadow: none;
}

* :-moz-ui-invalid {
  box-shadow: none;
}

* :focus {
  outline: none;
}

.highlights {
  color: transparent;
}

input,
textarea {
  outline: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-border-radius: 0;
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 14px;
  background-color: transparent;
  -webkit-appearance: none;
  box-shadow: none;
}

input:not([type='submit']) {
  width: 100%;
}

input[type='submit'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input::-ms-clear {
  display: none;
}

textarea {
  overflow: auto;
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  border-radius: 0;
}

input[type='radio'],
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

input[type='submit']::-moz-focus-inner {
  border: 0;
  outline: 0;
}

[contenteditable='true']::-moz-focus-inner {
  border: 0;
  outline: 0;
}

button::-moz-focus-inner {
  border: 0;
}

a:active,
a:focus,
a:hover {
  outline: none;
}

a {
  color: inherit;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  border: 0;
}

a[href*='javascript'] {
  cursor: initial;
}

strong,
b {
  font-weight: 700;
}

ul,
ol {
  padding-left: 0;
}

button {
  font-family: 'AvenirLTStd', 'Open Sans', Helvetica, Arial, sans-serif;
  padding: 0;
  outline: 0;
  border: 0;
}

button,
button:active,
button:focus,
button:hover,
input[type='submit'] {
  outline: none;
  cursor: pointer;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

header.header,
footer.footer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.footer {
  margin-top: 10px;
}

.footer-web {
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.footer-wrap {
  display: flex;
  border-top: 1px solid #F5FAFA;
  width: 100%;
}

main.layout {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.main_container {
  padding: 0 100px 0 100px;
}

body {
  font-family: 'AvenirLTStd', 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  min-width: 320px;
  line-height: 1.42em;
  margin: 0;
  color: #455a64;
  background-color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.margin-top-auto {
  margin-top: auto;
}

.hide--scroll {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

img {
  display: block;
  max-width: 100%;
}

.object-fit {
  width: 100%;
  height: 100%;
}

.object-fit.is--cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.cover-test {
  -o-object-fit: fill;
  object-fit: fill;
}

.object-fit.is--contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.container {
  width: 100%;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.header_cont {
  max-width: 1920px;
  display: flex;
  justify-content: center;
  padding: 0;
  justify-self: flex-start;
  margin: 0 auto;
}

.header-wrap {
  width: 1500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  margin-right: auto;
  margin-left: auto;
}

.header {
  text-align: center;
  padding: 33px 0;
}

.header-web {
  text-align: center;
  padding: 40px 0;
}

.header--main {
  z-index: 10;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  border-bottom: 1px solid rgba(152, 151, 151, 0.05);
}

.header--main-web {
  z-index: 10;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #F5FAFA;
  display: flex;
}

.header--second {
  z-index: 10;
  background-color: #ffffff;
  width: 100%;
  position: fixed;
  padding: 10px 0;
}

.header--second > div {
  margin-top: 6px;
}

.header--third {
  padding: 10px 0;
  border-bottom: 0;
}

.header--third .header__items {
  justify-content: space-between;
}

.header--detail {
  border-bottom: 0;
  padding: 14px 0;
  z-index: 1;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.header--detail .header__items {
  justify-content: space-between;
}

.header__items {
  display: flex;
}

.header__items-web {
  width: 100%;
  margin: 0 -15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  /*padding: 10px;*/
}

.header__items {
  margin: 0 -15px;
  align-items: center;
  justify-content: space-between;
}

.header__item {
  display: flex;
}

.header__item-web {
  display: flex;
  padding: 0 10px;
  margin: 0;
}

.header__item-logo {
  margin: 0 auto;
}

.header_text {
  line-height: 20px;
  padding: 15px 0;
  border-bottom: 3px solid white;
}

.header_text:hover {
  border-bottom: 3px solid #6BB9B1;
}

.modal_icon {
  margin-right: 20px;
}

.icons-header {
  margin: 0 0 0 20px;
}

.header__item-logo {
  margin: 0;
}

.search-web-container {
  width: calc(100% + 40px);
  margin-left: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5FAFA;
  height: 110px;
}

.sandwich {
  width: 20px;
  height: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.sandwich__part {
  display: block;
  width: 100%;
  background-color: #4c4c4c;
  height: 2px;
  border-radius: 10px;
}

.sandwich__part:nth-of-type(2) {
  width: calc(100% - 6px);
}

.sandwich__part:nth-of-type(3) {
  width: calc(100% - 12px);
}

.logo {
  display: inline-flex;
}

.layout {
  padding: 0 54px;
}

.layout--main {
  margin-top: 50px;
  padding: 0;
}

.layout--main-web {
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0;
  min-height: 100%;
}

.layout--main-web-change {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
}

.layout--default {
  padding: 0;
}

.layout__sect {
  padding: 20px 0 10px;
}

.layout_sect-web {
  padding: 40px 0 10px;
}

.layout__sect--main,
.layout__sect--main .container {
  height: 100%;
}

.layout__sect--main .container {
  display: flex;
  flex-direction: column;
}

.layout__sect--main,
.layout__sect--main .container {
  height: 100%;
}

.layout__sect--main .container {
  display: flex;
  flex-direction: column;
}

.layout__link {
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  margin-top: 12px;
}

.layout__link-part {
  color: #6bb9b1;
}

.layout__divider {
  position: relative;
  display: inline-flex;
  color: rgba(69, 90, 100, 0.5);
  width: 100%;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  margin: 20px 0 18px;
}

.layout__divider:before {
  margin-right: 20px;
}

.layout__divider:after {
  margin-left: 20px;
}

.layout__divider:before,
.layout__divider:after {
  content: '';
  flex: 1;
  background-color: #eff2f2;
  height: 1px;
}

.form-socials {
  overflow: hidden;
}

.form-socials__list {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}

.form-socials__item {
  width: calc(100% / 2);
  padding: 6px;
}

.form-socials__link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7fbfb;
  border-radius: 10px;
  padding: 15px;
}

.form {
  margin-top: 5px;
}

.form-web {
  margin: 65px auto 0;
  padding: 0 10px;
  width: 90%;
}

.form__items {
  margin: -5px;
}

.form__item {
  padding: 5px;
  position: relative;
}

.form__item_log_web {
  width: calc(100% + 50px);
  margin-left: -25px;
}

.form__item_signUp_web {
  width: calc(100% + 250px);
  margin-left: -125px;
}

.form__item-submit {
  margin-top: 15px;
}

.form__item-mandanatory {
  font-size: 10px;
  line-height: 12px;
}

.form__item-mandanatory .is--required {
  margin-right: 10px;
}

.form__item-mandanatory .is--required:after {
  font-size: 14px;
}

.form__input {
  border: 1px solid rgba(175, 204, 201, 0.26);
  border-radius: 10px;
  padding: 16px 38px 15px 50px;
  display: block;
  width: 100%;
}

.form__input-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

.form__input-select,
.form--second .form__input.form__input-select {
  padding-right: 38px;
  line-height: 16px;
}


.form--second .form__input {
  padding: 16px 20px 15px 20px;
  color: #455A64;
}

.form--second .form__item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form--second .form__items {
  margin: -10px -5px;
}

.form__item-label {
  color: #455A64;
  font-size: 13px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 12px;
}

.is--required {
  position: relative;
  padding-right: 10px;
}

.is--required:after {
  content: '*';
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  color: #6BB9B1;
  top: -3px;
  right: 0;
}

.form__input.is--error {
  border-color: #f00;
}

.form__input-icon {
  display: flex;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  justify-content: center;
}

.form__link {
  font-size: 10px;
  line-height: 12px;
  color: #6bb9b1;
}

.form__elements {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
  align-items: baseline;
  justify-content: space-between;
}

.form__element {
  padding: 6px;
}

.form__element-checkbox {
  margin-right: auto;
}

.checkbox {
  margin-top: 11px;
  position: relative;
}

.checkbox__part {
  display: block;
  font-size: 10px;
  line-height: 12px;
  cursor: pointer;
  padding-left: 32px;
  position: relative;
}

.checkbox__part:before {
  content: '';
  position: absolute;
  left: 0;
  top: -5px;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 1px solid rgba(175, 204, 201, 0.26);
  background-color: #fff;
}

.checkbox__part:after {
  content: '';
  position: absolute;
  left: 8px;
  top: -1px;
  width: 3px;
  height: 8px;
  border-bottom: 2px solid #455a64;
  border-right: 2px solid #455a64;
  transform: rotate(45deg);
  display: none;
}

input[type='checkbox']:checked + .checkbox__part:after {
  display: block;
}

.btn {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: #6bb9b1;
  padding: 8px 15px 7px;
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgba(107, 185, 177, 0.5);
  background-color: #fff;
}

.btn-web {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: #6bb9b1;
  padding: 8px 15px 7px;
  display: block;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgba(107, 185, 177, 0.5);
  background-color: #fff;
  height: 50px;
}

.btn-web-another {
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn--second {
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #4c4c4c;
}

.btn--second .btn__text {
  text-transform: none;
}

.btn__icon {
  display: block;
  margin-right: 8px;
  flex: 0 0 auto;
  fill: #4c4c4c;
}

.btn-submit {
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  border-radius: 10px;
  background-color: #6bb9b1;
  padding: 17px 15px;
}

.sprite-icon {
  fill: #6bb9b1;
  display: block;
}

.sprite-icon--second {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.sprite-icon--eye {
  fill: #d7dcdb;
}

.sprite-icon--eye.is--active {
  fill: #6bb9b1;
}

.btn-link {
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #4c4c4c;
}

.btn-link__text {
  text-shadow: 0 0 1px #4c4c4c;
}

.btn-link--second .btn-link__text {
  text-shadow: none;
  font-size: 13px;
  font-weight: 700;
}

.header__item-btn {
  margin: 0 auto;
}

.btn-link__icon {
  flex: 0 0 auto;
  margin-right: 6px;
  fill: #4c4c4c;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: -8px -12px -2px;
  padding: 10px 0;
}

.list__item {
  width: calc(100% / 3);
  padding: 8px 12px;
}

@media only screen and (min-width: 360px) {
  .list__item {
    width: calc(100% / 4);
  }
}

.list__item-block {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid rgba(107, 185, 177, 0.5);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3px;
}

.list__item-block > * {
  max-width: 33px;
  max-height: 33px;
}

.list__item-block_web {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  margin-bottom: 27px;
}

.list__item-block_web > * {
  max-width: 33px;
  max-height: 33px;
}

.list__item-link {
  width: 100%;
  display: block;
  text-align: center;
}

.list__item-link_web {
  text-align: center;
  position: relative;
  flex-direction: column;
  height: 200px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list__item-text {
  font-size: 14px;
  line-height: 17px;
  color: #6bb9b1;
}

.nav {
  border-top: 1px solid rgba(152, 151, 151, 0.05);
  overflow: hidden;
  padding: 15px 0;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.nav__list {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  align-items: center;
  padding: 0 30px;
}

.nav__list-item {
  padding: 0 10px;
}

.nav__list-link {
  display: flex;
  flex: 0 0 auto;
}

.nav__list-link.is--active .nav__list-icon {
  fill: #6bb9b1;
}

.nav__list-icon {
  display: block;
  fill: #4c4c4c;
  fill-rule: evenodd;
}

.blocks {
  overflow: hidden;
}

.blocks__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 10px 0 20px;
}

.blocks__top-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000;
}

.blocks__top-link {
  flex: 0 0 auto;
  font-size: 12px;
  line-height: 14px;
  color: #569d9e;
  padding: 8px;
  background-color: rgba(107, 185, 177, 0.2);
  border-radius: 20px;
  margin-left: auto;
}

.blocks__top-link-web {
  text-align: center;
  vertical-align: center;
  width: 130px;
  height: 46px;
  flex: 0 0 auto;
  font-size: 13px;
  line-height: 18px;
  color: rgba(107, 185, 177, 1);
  font-weight: 700;
  letter-spacing: 1px;
  padding: 14px;
  background-color: rgba(107, 185, 177, 0.1);
  border-radius: 10px;
  margin-left: 30px;
}

.blocks__body {
  overflow: auto;
  position: relative;
  top: 15px;
}

.cards-list {
  display: flex;
  padding: 0 14px 15px;
}
.cards-list-web {
  display: flex;
  padding: 0 14px 15px;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.cards-list-web > div {
  height: 300px;
}

.cards-list__item {
  padding: 0 5px;
}

.cards-list__item-web {
  padding: 0 5px;
  position: relative;
}

.horList_card_li {
  width: 100%;
  height: 100%;
}

.card {
  display: block;
  border-radius: 20px 20px 10px 10px;
  border: 1px solid #eff2f2;
  overflow: hidden;
  width: 252px;
  height: 100%;
}

.card-horList-web {
  display: block;
  border-radius: 10px;
  border: 1px solid #eff2f2;
  overflow: hidden;
  width: 252px;
  height: 100%;
}

.card-horList-web_profile {
  display: block;
  border-radius: 10px;
  border: 1px solid #eff2f2;
  overflow: hidden;
  width: 322px;
  height: 100%;
}

.card-horList-web2 {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #eff2f2;
  width: 192px;
  height: 100%;
}

.card-placesList-web {
  display: block;
  border-radius: 10px;
  border: 1px solid #eff2f2;
  width: 252px;
  height: 192px;
  margin-right: 40px;
  margin-bottom: 40px;
}

.card-placesList-web-cat {
  display: block;
  border-radius: 10px;
  border: 1px solid #eff2f2;
  width: 322px;
  height: 202px;
  margin-right: 40px;
  margin-bottom: 40px;
}

.card.card--second {
  width: 192px;
}

.card--third,
.card--fourth {
  width: 142px;
}

.card--fourth {
  border: 0;
}

.card--fourth .card__img {
  height: 110px;
}

.card--third .card__info-wrap,
.card--fourth .card__info-wrap {
  margin-top: 0;
}

.card--third .card__info {
  padding: 6px 2px 6px 10px;
}

.card--fourth .card__info {
  padding: 6px 0 10px;
}

.card__img {
  border-bottom-right-radius: 20px;
  overflow: hidden;
  height: 130px;
  position: relative;
}

.card__img_placesList {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  height: 130px;
  position: relative;
}

.card__img_placesList-big {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  height: 130px;
  position: relative;
}

.card__img-horList1 {
  overflow: hidden;
  height: 130px;
  position: relative;
}

.card__img-horList2 {
  overflow: hidden;
  height: 120px;
  position: relative;
}

.card__img-web {
  border-bottom-right-radius: 20px;
  overflow: hidden;
  height: 130px;
  width: 80px;
}

.card__label-new {
  padding: 4px 5px 2px 5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: absolute;
  bottom: 0;
  left: 10px;
  background: #F6B345;
  color: #ffffff;
  font-style: normal;
  font-weight: 750;
  font-size: 11px;
}

.card__info {
  padding: 6px 10px 8px;
}

.card__info-web {
  padding: 6px 5px 8px;
  height: 60px;
}

.card__info-web-big {
  padding: 6px 5px 8px;
  height: 70px;
}

.card__info-horList2 {
  padding: 6px 10px 8px;
  /*border-radius: 0 0 10px 10px;*/
  /*border-width: 0 1px 1px 1px;*/
  /*border-style: solid;*/
  /*border-color: #eff2f2;*/
  height: 70px;
}

.card__info-text {
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  color: #989797;
}

.card__info-text_horList1 {
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  color: #989797;
}

.card__info-text span {
  display: block;
  color: #6BB9B1;
  font-size: 15px;
  line-height: 18px;
  padding: 2px 0;
}

.card__info-wrap {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.card__info-wrap_placesList {
  display: flex;
  align-items: center;
  margin-top: 6px;
  max-width: 205px;
}

.card__info-wrap_placesList-big {
  display: flex;
  align-items: center;
  margin-top: 6px;
  max-width: 280px;
}

.card__info-wrap_horList1 {
  display: flex;
  align-items: center;
  margin-top: 6px;
  text-align: left;
}

.card__info-wrap_horList2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 6px;
}

.card__info-wrap-web {
  text-align: left;
  margin-top: 6px;
}

.card__info-icon {
  flex: 0 0 auto;
  margin: 0 7px 0 0;
}

.card__info-icon_horList2 {
  flex: 0 0 auto;
  margin: 6px 7px 0 0;
}

.card__info-icon--grey > svg {
  fill: #6d6d6d;
  fill-rule: evenodd;
  max-width: 18px;
  max-height: 14px;
  display: inline-block;
}

.card__info-title {
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  color: #111;
}

.card__info-title-places-web {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: #111;
}

.card__info-title-places-big {
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  color: #111;
}

.card__info-title-horList1 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: #111;
}

.card__info-title-horList2 {
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  color: #111;
}

.card__info-title-web {
  margin-top: 10px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  color: #111;
}

.form__error {
  color: #f00;
  margin-top: 15px;
}

.alert {
  padding-bottom: 20px;
  display: inline-block;
  position: relative;
}

.alert > span {
  display: inline-block;
  left: 0;
  right: 0;
  padding: 2px 15px;
  background-color: #6bb9b1;
  color: #fff;
  text-align: center;
  top: 50px;
  position: absolute;
  z-index: 19;
}

.alert.aller--logo {
  position: absolute;
  left: 0;
  right: 0;
}

.alert.alert.aller--logo > span {
  top: 0;
}

.header__item-icon {
  display: block;
}

.form-search__icon {
  display: block;
  fill: #6bb9b1;
  fill-rule: evenodd;
}

.form__label {
  display: block;
}

.form-search__submit {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.form-search__clear {
  text-align: center;
  color: #6bb9b1;
  border-radius: 100px;
  border: #6bb9b1 solid 1px;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  height: 16px;
  width: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.form-search__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

.fixed-form-search {
  top: 40px;
  left: 0;
  right: 0;
  position: fixed;
  padding-right: 20px;
  padding-left: 20px;
  width: auto;
  background-color: #ffffff;
  padding-top: 10px;
  z-index: 18;
}

.form-search {
  position: relative;
  margin-top: 3px;
}

.form-search-web {
  position: relative;
  margin-top: 3px;
}

.form-search-web .form__input {
  padding: 14px 50px 14px 50px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  border: 1px solid rgba(107, 185, 177, 0.26);
  border-radius: 10px;
  background-color: #ffffff;
  width: 450px;
}

.fixed-form-search-space {
  height: 30px;
}

.form-search .form__input {
  padding: 6px 40px 6px 40px;
  font-size: 13px;
  border: 1px solid rgba(107, 185, 177, 0.26);
  border-radius: 20px;
  background-color: #ffffff;
}

.form-search.form-profile .form__input {
  padding: 6px 6px 6px 6px;
  font-size: 13px;
  border: 1px solid rgba(107, 185, 177, 0.26);
  border-radius: 20px;
}

.form-search input::-moz-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  opacity: 1;
  color: #c4c4c4;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.form-search input:-moz-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  opacity: 1;
  color: #c4c4c4;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.form-search input::-webkit-input-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  opacity: 1;
  line-height: 1.6;
  color: #c4c4c4;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.form-search input:-ms-input-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  opacity: 1;
  color: #c4c4c4;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.form-search input:focus::-webkit-input-placeholder {
  color: transparent;
  outline: none;
}

.form-search input:focus::-moz-placeholder {
  color: transparent;
  outline: none;
}

.form-search input:focus:-moz-placeholder {
  color: transparent;
  outline: none;
}

.form-search input:focus:-ms-input-placeholder {
  color: transparent;
  outline: none;
}

.form-search-web input::-moz-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  opacity: 1;
  color: #c4c4c4;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.form-search-web input:-moz-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  opacity: 1;
  color: #c4c4c4;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.form-search-web input::-webkit-input-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  opacity: 1;
  line-height: 1.6;
  color: #c4c4c4;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.form-search-web input:-ms-input-placeholder {
  font-family: 'AvenirLTStd', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  opacity: 1;
  color: #c4c4c4;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.form-search-web input:focus::-webkit-input-placeholder {
  color: transparent;
  outline: none;
}

.form-search-web input:focus::-moz-placeholder {
  color: transparent;
  outline: none;
}

.form-search-web input:focus:-moz-placeholder {
  color: transparent;
  outline: none;
}

.form-search-web input:focus:-ms-input-placeholder {
  color: transparent;
  outline: none;
}

.form-profile .form__input {
  padding: 6px 6px;
  font-size: 13px;
  border: 1px solid rgba(107, 185, 177, 0.26);
  border-radius: 20px;
}

.btn-favorite {
  display: flex;
  background-color: transparent;
}

.btn-favorite__icon {
  display: block;
  fill: #6bb9b1;
}

.card.card--full {
  max-width: 767px;
  width: 100%;
}

.card.card--full:not(:last-of-type) {
  margin-bottom: 20px;
}

.card.card--full .card__img {
  border-bottom-right-radius: 0;
}

.card.card--full .card__info {
  padding: 20px 44px 15px 10px;
  position: relative;
}

.card.card--full .card__info-wrap {
  margin-top: 8px;
}

.card.card--full .btn-favorite {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
}

.filter--open * {
  color: #6bb9b1;
  fill: #6bb9b1;
}

.form-filter {
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-filter__row:not(:last-of-type) {
  padding-bottom: 10px;
}

.form-filter__row--tags .form-filter__items {
  justify-content: left;
}

.form-filter__items {
  margin: 0 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.form-filter__item {
  padding: 5px;
}


.form-filter__link {
  font-size: 13px;
  line-height: 1;
  text-align: center;
  color: #8dbfba;
  border: 1px solid rgba(107, 185, 177, 0.2);
  border-radius: 20px;
  padding: 9px 15px 7px;
  background: transparent;
}

.form-filter__link.is--active {
  background-color: #6bb9b1;
  color: #fff;
}

.form-filter__link-web {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #8dbfba;
  border: 1px solid rgba(107, 185, 177, 0.2);
  border-radius: 20px;
  padding: 9px 15px 7px;
  background: transparent;
}

.form-filter__link-web.is--active {
  background-color: #6bb9b1;
  color: #fff;
}

.form-filter__title {
  font-size: 10px;
  line-height: 12px;
  width: 100%;
  margin-bottom: 2px;
}

.form-filter__title-web {
  font-size: 12px;
  line-height: 16px;
  /*margin-bottom: 2px;*/
  font-weight: 500;
  color: #455A64;
}

.form-filter__row:last-of-type {
  margin-bottom: -24px;
}

.form-filter__row:first-of-type {
  margin-bottom: 0;
}

.form-filter__row--search_page {
  margin-top: 20px;
}

.layout__detail-head {
  position: fixed;
  top: 47px;
  left: 0;
  right: 0;
  z-index: 0;
}

.layout__detail-head-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.layout__detail-img {
  height: 320px;
  position: relative;
}

.layout__detail-img-props {
  position: relative;
  height: 210px;
  width: 160px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.layout__categories {
  position: relative;
  height: 200px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(107, 185, 177, 0.07);
  font-size: 14px;
  line-height: 19px;
  color: #8DBFBA;
  font-weight: 400;
  border-radius: 50px;
}

.list__item-text_cat {
  font-size: 14px;
  line-height: 19px;
  color: #8DBFBA;
  font-weight: 400;
}

.layout__categories:hover {
  background: rgba(107, 185, 177, 1);
  color: white;
}

.layout__categories:hover span {
  color: white;
}



.layout__detail-img:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 17%);
}

.layout__detail-body {
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: 294px;
  padding: 18px 0;
}

.layout__detail-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #111;
  margin-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
}

.layout__detail-title-web {
  font-weight: 700;
  font-size: 32px;
  line-height: 29px;
  color: #111;
  margin-bottom: 6px;
  padding-left: 20px;
  padding-right: 10px;
}

.login__h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 29px;
  color: #111;
  margin-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  align-self: center;
  text-align: center;
}

.login__hr {
  color: #6BB9B1;
  background-color: #6BB9B1;
  height: 3px;
  width: 70px;
  align-self: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: -30px;
  border: none;
  border-radius: 2px;
}

.restore__hr {
  color: #6BB9B1;
  background-color: #6BB9B1;
  height: 3px;
  width: 70px;
  align-self: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 2px;
}

.profile__hr {
  color: #6BB9B1;
  background-color: #6BB9B1;
  height: 3px;
  width: 70px;
  margin-top: 15px;
  margin-bottom: 20px;
  border: none;
  border-radius: 2px;
  margin-left: 0;
  align-self: flex-start;
}

.profile-button-update {
  width: 190px;
  height: 46px;
  background-color: #6BB9B1;
  border-radius: 10px;
  color: white;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  vertical-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.disabled-button {
  opacity: 0.2;
  cursor: default !important;
}

.modal_header {
  cursor: default !important;
}

.profile-button-logout {
  margin-left: auto;
  width: 190px;
  height: 46px;
  background-color: white;
  border-radius: 10px;
  color: #6BB9B1;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  vertical-align: center;
  margin-right: 20px;
  border: 1px solid rgba(107, 185, 177, 0.5);
  text-transform: uppercase;
}

.main__hr {
  color: #6BB9B1;
  background-color: #6BB9B1;
  height: 3px;
  width: 70px;
  margin-bottom: 50px;
  border: none;
  border-radius: 2px;
  text-align: left;
  justify-self: flex-start;
  align-self: flex-start;
}

.main_horList__hr {
  color: #6BB9B1;
  background-color: #6BB9B1;
  height: 3px;
  width: 70px;
  margin-bottom: 50px;
  margin-top: 14px;
  border: none;
  border-radius: 2px;
  text-align: left;
  justify-self: flex-start;
  align-self: flex-start;
}

.signUp__hr {
  color: #6BB9B1;
  background-color: #6BB9B1;
  height: 3px;
  width: 70px;
  align-self: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: -30px;
  border: none;
  border-radius: 2px;
}

.layout__detail-subtitle {
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  color: #989797;
  padding-left: 20px;
  padding-right: 20px;
}

.layout__detail-subtitle-web {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #989797;
  padding-left: 20px;
  padding-right: 20px;
}

.detail-web-promo {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: white;
  padding: 5px;
  margin-left: 10px;
  border-radius: 20px;
  background-color: #EDB55A;
  align-self:flex-start;
;
}

.layout__detail-btn {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0 20px;
}

.layout__detail-btn-web {
  width: 100%;
  max-width: 250px;
}

.btn--third {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6BB9B1;
  border-radius: 10px;
  padding: 9px 15px 8px;
}

.btn--third .btn__text {
  font-weight: 750;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
}

.call_text {
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 1px !important;
  font-weight: 700 !important;
}

.call_btn {
  cursor: auto !important;
}

.btn__icon-img {
  margin-right: 6px;
}

.layout__detail-divider {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  position: relative;
}

.layout__detail-divider-web {
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 30px;
  position: relative;
  max-width: 250px;
  width: 100%;
}

.layout__detail-divider:after {
  content: '';
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-bottom: 1px solid #EFF2F2;
}

.block-inf {
  margin-top: 16px;
}

.block-inf a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.block-inf__title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #111;
  margin-bottom: 23px;
}

.block-inf__blocks {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
}

.block-inf__blocks > * {
  flex: 1 1 50%;
  margin-bottom: 15px;
}

.block-inf__blocks > * > div {
  display: flex;
  align-items: center;
}

.block-inf__blocks-web {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
  align-items: flex-start;
}

.block-inf__blocks-web > * {
  flex: 1 1 auto;
  margin-bottom: 15px;
  margin-right: 1%;
  min-width: 250px;
  max-width: 250px;
}

.block-inf__blocks-web > * > div {
  background: rgba(107, 185, 177, 0.07);
  border-radius: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 10px;
  height: 50px;
  min-width: 24%;
  max-width: 250px;
}

.block-inf__rows {
  display: flex;
  margin: 0 -8px;
}

.block-inf__rows-web {
  display: flex;
  margin: 0 20px;
}

.block-inf__rows:not(:last-of-type) {
  margin-bottom: 30px;
}

.block-inf__row {
  padding: 0 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.block-inf__row-web {
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.block-inf__row:first-of-type {
  width: 96px;
  flex: 0 0 auto;
  align-self: flex-start;
}

.block-inf__row-web:first-of-type {
  width: 90px;
  flex: 0 0 auto;
  align-self: flex-start;
}

.block-inf__row:nth-of-type(2) {
  margin-right: auto;
}

.block-inf__row-web:nth-of-type(2) {
  margin-right: 15px;
}

.block-inf__row:last-of-type {
  align-self: flex-start;
}

.block-inf__row-web:last-of-type {
  align-self: flex-start;
}

.block-inf__row-web:nth-of-type(3) {
  padding-right: 40px;
}

.block-inf__row__rating {
  min-height: 24px;
}


.block-inf__link {
  display: flex;
  align-items: center;
  line-height: 16px;
  background: transparent;
}

.block-inf__icon {
  display: block;
  flex: 0 0 auto;
  margin-right: 5px;
  fill: #4c4c4c;
  align-self: baseline;
}

.block-inf__text {
  font-size: 13px;
  line-height: 16px;
  color: #4c4c4c;
  white-space: pre-wrap;
}

.block-inf__text-rating {
  font-size: 13px;
  line-height: 16px;
  color: #4c4c4c;
  white-space: pre-wrap;
  width: max-content;
}

.block-inf__text.block-inf__text__links {
  overflow: hidden;
}

.block-inf__text small {
  font-size: 10px;
  line-height: 1.3;
}

.block-inf__label {
  color: #6BB9B1;
}

.layout__sect-title {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  color: #111;
  text-align: center;
}

.auth-row__link {
  display: flex;
  align-items: center;
}

.auth-row__icon {
  display: block;
  margin-right: 13px;
}

.auth-row__text {
  font-weight: 750;
  font-size: 15px;
  color: #569D9E;
}

.auth-row {
  margin-top: auto;
  padding-bottom: 15px;
}

.layout__sect-list {
  margin-top: 15px;
}

.layout__sect-item {
  border-top: 1px solid #EFF2F2;
  border-bottom: 1px solid #EFF2F2;
  margin-bottom: -1px;
  padding: 10px 0;
}

.layout__sect-text {
  color: #111;
  font-size: 15px;
  padding: 0 5px;
}

.layout__sect-text a[href*='mailto'] {
  color: #6BB9B1;
}

.layout__sect-contains {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -5px;
}

.layout__sect-select .is--selected {
  color: #6BB9B1;
  padding-right: 30px;
  position: relative;
}

.layout__sect-select .is--selected:after {
  content: '';
  display: block;
  width: 14px;
  height: 8px;
  border-top: 2px solid #6BB9B1;
  border-right: 2px solid #6BB9B1;
  position: absolute;
  top: 1px;
  right: 0;
  transform: rotate(135deg);
}

.layout__sect-select .layout__sect-text {
  width: 100%;
}

.layout__sect-select .layout__sect-link {
  width: 100%;
}

.close {
  width: 20px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
  position: relative;
  background: transparent;
}

.close_modal {
  position: relative;
  width: 0;
  height: 0;
  z-index: 25;
  left: 465px;
  bottom: 105px;
}

.close__part {
  width: 100%;
  height: 3px;
  background-color: #4C4C4C;
  display: block;
  border-radius: 2px;
}

.close__part:first-of-type {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 8px
}

.close__part:last-of-type {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  top: 8px
}

.layout__sect-links .layout__sect-link {
  position: relative;
  padding-right: 30px;
}

.icon-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: block;
}

.switch__wrap {
  border: 1px solid rgba(107, 185, 177, 0.26);
  border-radius: 20px;
  width: 62px;
  height: 34px;
  position: relative;
}

.switch__text {
  font-size: 15px;
  color: #111;
}

.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0;
}

.switch-web {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 22px 0;
}

.switch-web-div {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  /*padding: 22px 0;*/
  margin-left: auto;
}

.modalOnMap {
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectModal {
  height: 46px;
  width: 350px;
  margin-left: 20px;
  border-radius: 10px;
  padding: 14px 20px;
  border: 1px solid rgba(175, 204, 201, 0.26);
  background-color: #FFFFFF;
  /*padding-right: 38px;*/
  line-height: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  position: relative;
}

.map_button {
  width: 200px;
  height: 46px;
  text-align: center;
  vertical-align: center;
  background-color: #6BB9B1;
  border-radius: 10px;
  font-size: 13px;
  line-height: 18px;
  color: white;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 20px;
}

.switch__text {
  margin-right: 15px;
}

.switch__part {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.switch__part:before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #EFF2F2;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  transition: all .2s ease;
}

.switch__wrap input:checked + .switch__part:before {
  left: 31px;
  background-color: #6BB9B1;
}

.socials {
  margin: -7px -15px;
  display: flex;
  flex-wrap: wrap;
}

.socials-web {
  margin: -10px 0;
  display: flex;
  flex-wrap: wrap;
}

.socials-web-detail {
  margin: -7px -15px;
  display: flex;
  flex-wrap: wrap;
}

.socials-wrap {
  border-top: 1px solid #EFF2F2;
  border-bottom: 1px solid #EFF2F2;
  padding: 10px 0;
  margin-top: 20px;
}

.socials-wrap-web {
  padding: 10px 0;
  margin-top: 20px;
}

.socials__item {
  padding: 7px 15px;
  width: calc(100% / 6);
}

.socials__item-detail {
  padding: 7px 15px;
  width: 50px;
  margin-right: 10px;
}

.socials__item-detail-rating {
  padding: 7px 15px;
  width: 100px;
  margin-right: 10px;
}

.socials__link {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6bb9b1;
  margin: auto;
}

.socials__link-web {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6bb9b1;
  margin: auto;
}

.socials__link-icon {
  fill: #fff;
}

.block-inf--second .block-inf__row {
  width: calc(100% / 2);
}

.block-inf--second .block-inf__rows:not(:last-of-type) {
  margin-bottom: 15px;
}

.block-inf__img {
  display: flex;
  margin-right: 5px;
  min-height: 24px;
  align-self: flex-start;
}

.placePosterDot {
  position: absolute;
  bottom: 40px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.placePosterDot-web {
  position: absolute;
  bottom: 15px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.placePosterDot > li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.placePosterDot-web > li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.placePosterDot > li > button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.placePosterDot-web > li > button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.placePosterDot > li.slick-active > button:before {
  opacity: .50;
  color: rgb(150, 150, 150);
}

.placePosterDot-web > li.slick-active > button:before {
  opacity: 1;
  color: white;
}

.placePosterDot > li > button:before {
  font-family: 'slick';
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: rgb(150, 150, 150);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placePosterDot-web > li > button:before {
  font-family: 'slick';
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .4;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.layout__sect-title {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  color: #111;
  text-align: center;
}

.auth-row__link {
  display: flex;
  align-items: center;
  background: transparent;
}

.auth-row__icon {
  display: block;
  margin-right: 13px;
}

.auth-row__text {
  font-weight: 750;
  font-size: 15px;
  color: #569D9E;
}

.auth-row {
  margin-top: auto;
  padding-bottom: 15px;
}

.layout__sect-list {
  margin-top: 15px;
}

.layout__sect-item {
  border-top: 1px solid #EFF2F2;
  border-bottom: 1px solid #EFF2F2;
  margin-bottom: -1px;
  padding: 10px 0;
}

.layout__sect-text {
  color: #111;
  font-size: 15px;
  padding: 0 5px;
}

.layout__sect-text a[href*='mailto'] {
  color: #6BB9B1;
}

.layout__sect-contains {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -5px;
}

.layout__sect-select .is--selected {
  color: #6BB9B1;
  padding-right: 30px;
  position: relative;
}

.layout__sect-select .is--selected:after {
  content: '';
  display: block;
  width: 14px;
  height: 8px;
  border-top: 2px solid #6BB9B1;
  border-right: 2px solid #6BB9B1;
  position: absolute;
  top: 1px;
  right: 0;
  transform: rotate(135deg);
}

.layout__sect-select .layout__sect-text {
  width: 100%;
}

.layout__sect-select .layout__sect-link {
  width: 100%;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  text-align: left;
  background-color: transparent;
  font-size: 15px;
}

.close {
  width: 20px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
  position: relative;
}

.close__part {
  width: 100%;
  height: 3px;
  background-color: #4C4C4C;
  display: block;
  border-radius: 2px;
}

.close__part:first-of-type {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 8px
}

.close__part:last-of-type {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  top: 8px
}

.layout__sect-links .layout__sect-link {
  position: relative;
  padding-right: 30px;
}

.icon-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: block;
}

.switch__wrap {
  border: 1px solid rgba(107, 185, 177, 0.26);
  border-radius: 20px;
  width: 62px;
  height: 34px;
  position: relative;
}

.switch__text {
  font-size: 15px;
  color: #111;
}

.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0;
}

.switch__text {
  margin-right: 15px;
}

.switch_text_desktop {
  font-size: 15px;
  font-weight: 700;
  color: #111;
  margin-right: 15px;
  line-height: 20px;
}

.switch__part {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.switch__part:before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #EFF2F2;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  transition: all .2s ease;
}

.switch__wrap input:checked + .switch__part:before {
  left: 31px;
  background-color: #6BB9B1;
}

.layout__sect-subtitle {
  margin-top: 6px;
  text-align: center;
  font-size: 13px;
  color: #455A64;
  margin-bottom: 12px;
}

.select-wrapper {
  position: relative;
}

.select-wrapper:after {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(135deg);
  pointer-events: none;
  display: block;
  width: 6px;
  height: 6px;
  border-top: 2px solid #4C4C4C;
  border-right: 2px solid #4C4C4C;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #ffffff;
}

/*******nav*********/
.menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  overflow: hidden auto;
  transition: all .2s ease;
  transform: translateX(-100%);
}

.menu.is--open {
  transform: none;
}

.menu__top {
  padding: 10px 0;
}

.menu__middle {
  padding: 30px 0;
}

.menu__bottom {
  margin-top: auto;
  padding-bottom: 67px;
}

.socials--second .socials__item {
  width: calc(100% / 3);
}

.socials-wrap--second {
  display: flex;
  align-items: center;
}

.socials-wrap__text {
  flex: 0 0 auto;
  margin-right: auto;
  padding-right: 15px;
}

.menu__list-link {
  display: flex;
  align-items: center;
}

.menu__list-link-web {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.menu__list-icon {
  fill: #4c4c4c;
  display: block;
}

.menu__list-wrap {
  flex: 0 0 auto;
  width: 20px;
  margin-right: 11px;
  display: flex;
  justify-self: center;
}

.menu__list-text {
  color: #111111;
  font-size: 15px;
}

.menu__list-text-web {
  color: black;
  font-size: 15px;
  font-weight: 500;
  font-family: 'AvenirLTStd';
  overflow: hidden;
  white-space: nowrap;
}

.menu__list {
  margin: -15px 0 10px;
}

.menu__list-item {
  padding: 15px 0;
}

.sign-in_web {
  background: rgba(107,	185,	177, 0.07);
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: calc(100% + 108px);
  max-height: 100%;
  min-height: 800px;
  margin: 25px -54px -20px;
  padding-top: 20px;
}

.profile-web-container {
  background: rgba(107,	185,	177, 0.07);
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: calc(100% + 108px);
  height: 100%;
  margin: 0 -54px 0;
  padding: 50px 0;
}

.sign-in_web-popUp {
  background: rgba(0 , 0, 0, 0.6);
  display: flex;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 100;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: calc(100% + 108px);
  height: calc(100%);
  margin: 0 -54px 0;
  /*padding-top: 20px;*/
  overflow: hidden;
}

.confirm_email-web {
  background: rgba(107,	185,	177, 0.07);
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: calc(100% + 108px);
  height: 100%;
  margin: 50px -54px 50px;
  padding-top: 20px;
  padding-bottom: 40px;
}

.wrap-web {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  justify-self: center;
  min-width: 460px;
  min-height: 550px;
  max-height: 70%;
  align-self: center;
  padding: 10px;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.wrap-web-profile {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  align-content: center;
  align-items: center;
  justify-self: center;
  min-width: 1200px;
  min-height: 600px;
  align-self: center;
  border-radius: 10px;
  padding: 60px 50px;
}

.profile-form-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: calc(100%);
  height: calc(100%);
  justify-content: center;
  align-items: flex-start;
}

.profile-h1-web {
  line-height: 40px;
  font-size: 36px;
  font-weight: 700;
  color: #111111;
  text-align: left;
}

.profile-web-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100%);
}

.wrap-web-contact {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  justify-self: center;
  min-width: 500px;
  min-height: 600px;
  max-height: 95%;
  align-self: center;
  padding: 0 20px 10px;
  border-radius: 10px;
}

.wrap-web-restore {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  justify-self: center;
  min-width: 560px;
  min-height: 340px;
  max-height: 95%;
  align-self: center;
  padding: 20px 40px;
  border-radius: 10px;
}

.wrap-web-restore-popUp {
  background: #ffffff;
  display: flex;
  position: absolute;
  left: calc(50% - 280px);
  top: calc(35% - 170px);
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  justify-self: center;
  min-width: 560px;
  min-height: 340px;
  max-height: 95%;
  align-self: center;
  padding: 20px 40px;
  border-radius: 10px;
}

.about__middle-block {
  padding: 20px 0;
  background-color: #6BB9B1;
  width: calc(100% + 40px);
  margin-left: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.about__top-block {
  margin-top: 20px;
  padding: 10px 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.about__bottom-block {
  margin-top: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.about__middle-div {
  margin: 0 30px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  text-align: center;
}

.about__middle-h1 {
  font-size: 70px;
  font-weight: 700;
  color: white;
}

.about__middle-h3 {
  font-size: 36px;
  font-weight: 300;
  color: white;
}

.about__wrap {
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.about__wrap-top div {
  margin-left: 30px;
  margin-right: 30px;
}

.about__wrap-top h1 {
  margin-top: 0;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: black;
}

.about__wrap-top h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: black;
}

.about__wrap-top p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: black;
}

.about__wrap-top img {
  margin: 0 30px;
  padding: 10px 0;
}

.about__wrap-top {
  max-width: 1080px;
  /*min-width: 60%;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.about__wrap-bottom {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  justify-items: center;
}

/*.about__wrap-bottom div {*/
/*  margin-left: 30px;*/
/*  margin-right: 30px;*/
/*}*/

.about__wrap-bottom div:first-of-type {
  margin-left: 30px;
  margin-right: calc(10%);
}

.about__wrap-bottom div:last-of-type {
  margin-left: 0;
  margin-right: 30px;
}

.about__wrap-bottom h3 {
  max-width: 600px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: black;
}

.about__wrap-bottom p {
  max-width: 600px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: black;
}
.layout__detail-web {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  padding-top: 100px;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.place_cont {
  max-width: 1920px;
  margin: 0 auto;
}

.detail-web {
  padding: 0 60px;
  min-width: calc(100%);
  /*margin-left: calc(20%);*/
  max-width: 1250px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-self: flex-start;
  /*margin-right: calc(20%);*/
}

.slider-wrap {
  width: 360px;
  height: 320px;
  display: inline-block;
}

.slider-wrap-prop {
  max-width: 1250px;
  height: 240px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  justify-self: center;
}

.slider-wrap-categories {
  max-width: 1400px;
  height: 220px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.favorite_pos {
  position: relative;
  right: calc(5%);
  top: 10px;
  align-self: flex-end;
  width: 0;
  height: 0;
}

.favorite_pos_placesList {
  width: 0;
  height: 0;
  position: relative;
  left: 220px;
  top: 30px;
  align-self: flex-end;
}

.favorite_pos_placesList-big {
  width: 0;
  height: 0;
  position: relative;
  left: 290px;
  top: 25px;
  align-self: flex-end;
}

.detail-web-info {
  width: calc(100% - 450px);
  margin-left: 20px;
}

.wrap-detail-block {
  width: 100%;
  flex-basis: 46%;
  margin-right: calc(3%);
  margin-bottom: 40px;
  align-self: flex-start;
  justify-self: center;
}

.wrap-detail-block-rating {
  width: 100%;
  flex-basis: 40%;
  padding-top: 5px;
  align-self: center;
  justify-self: center;
  padding-left: 20px;
}

.detail-info-wrap-flex {
  display: flex;
  flex: 1 1 49%;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: space-around;
  margin-left: 20px;
  margin-top: 40px;
  width: 100%;
}

.detail-head-web {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.detail-btn-div {
  display: flex;
  flex: 1 1 48%;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.arrow_select {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  pointer-events: none;
}

.position_arrow_select {
  position: relative;
  width: 0;
  height: 0;
  right: 30px;
  bottom: 10px;
  pointer-events: none;
}

.slider-prop > div > div > div > div{
  height: 210px;
  max-width: 160px;
}

.slider-prop > div > div > div {
  height: 210px;
  max-width: 200px !important;
}

.slider-categories > div > div > div > div{
  height: 200px;
  max-width: 140px;
}

.slider-categories > div > div > div {
  height: 200px;
  max-width: 180px !important;
}

.slider-horList1 > div > div > div > div{
  height: 190px;
  max-width: 250px;
}

.slider-horList1 > div > div > div {
  height: 190px;
  max-width: 290px !important;
}

.slider-horList2 > div > div > div > div{
  height: 190px;
  max-width: 200px;
}

.slider-horList2 > div > div > div {
  height: 190px;
  max-width: 240px !important;
}

.slider-horListProfile > div > div > div > div{
  height: 200px;
  max-width: 320px;
}

.slider-horListProfile > div > div > div {
  height: 200px;
  max-width: 360px !important;
}

.positionBack {
  justify-self: flex-start;
  align-self: flex-start;
  width: 0;
  height: 0;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}

.positionBackArrow {
  justify-self: flex-start;
  align-self: flex-start;
  width: 0;
  height: 0;
  position: relative;
  bottom: 20px;
  left: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.positionBackWord {
  justify-self: flex-start;
  align-self: flex-start;
  width: 0;
  height: 0;
  position: relative;
  bottom: 40px;
  left: 60px;
  display: flex;
  align-items: center;

}

.positionBackWord span {
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  min-width: 100px;
  padding-left: 20px;
  color: black;
}

.positionBackArrow span {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
}

.map_back_arrow {
  position: relative;
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block !important;
  padding: 5px;
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
}

.map_back_span {
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  min-width: 100px;
  padding-left: 20px;
  color: black;
}

.map_back_div {
  justify-self: flex-start;
  /*margin-right: 20px;*/
}

.map_back_div2 {
  margin-left: -10px;
  justify-self: flex-start;
}

.wrap_map_back {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.wrap_main_back {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  bottom: 30px;
}

/*.wrapBack_div {*/
/*  display: inline;*/
/*}*/

.center-web {
  text-align: center;
}

.slider-prop {
  width: 1180px;
}

.slider-categories {
  width: 1400px;
}

.slider-horList1 {
  width: 1400px;
}

.slider-horListProfile {
  width: 1080px;
}

.slider-horList2 {
  width: 1400px;
}

.slider-prop > .slick-arrow {
  color: black;
  background-color: white;
  border-radius: 50%;
}

.slider-prop > .slick-prev {
  top: -30px;
  left: calc(100% - 100px)
}

.slider-prop > .slick-next {
  top: -30px;
  right: 30px;
}


.slider-prop > .slick-prev:before {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
}

.slider-prop > .slick-next:before {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.slider-categories > .slick-arrow {
  color: black;
  background-color: white;
  border-radius: 50%;
}

.slider-categories > .slick-prev {
  top: -75px;
  left: calc(100% - 110px)
}

.slider-categories > .slick-next {
  top: -75px;
  right: 40px;
}


.slider-categories > .slick-prev:before {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
}

.slider-categories > .slick-next:before {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.slider-horList1 > .slick-arrow {
  color: black;
  background-color: white;
  border-radius: 50%;
}

.slider-horList1 > .slick-prev {
  top: -90px;
  left: calc(100% - 110px)
}

.slider-horList1 > .slick-next {
  top: -90px;
  right: 40px;
}

.slider-horList1 > .slick-prev:before {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
}

.slider-horList1 > .slick-next:before {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.slider-horList2 > .slick-arrow {
  color: black;
  background-color: white;
  border-radius: 50%;
}

.slider-horList2 > .slick-prev {
  top: -90px;
  left: calc(100% - 110px)
}

.slider-horList2 > .slick-next {
  top: -90px;
  right: 40px;
}

.slider-horList2 > .slick-prev:before {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
}

.slider-horList2 > .slick-next:before {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.slider-horListProfile > .slick-arrow {
  color: black;
  background-color: white;
  border-radius: 50%;
}

.slider-horListProfile > .slick-prev {
  top: -90px;
  left: calc(100% - 110px)
}

.slider-horListProfile > .slick-next {
  top: -90px;
  right: 40px;
}

.slider-horListProfile > .slick-prev:before {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
}

.slider-horListProfile > .slick-next:before {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.form-input-back {
  background-color: #fff;
}

.props-img {
  border-radius: 16px 16px 16px 0;
  width: 160px;
  height: 125px;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.wrap-prices {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: flex-start;
  /*margin-left: calc(20%);*/
  padding-left: 60px;
}

.prices-h1 {
  font-weight: 700;
  font-size: 24px;
  padding: 10px 0 0 0;
  color: black;
}

.addInfo-h1 {
  font-weight: 700;
  font-size: 24px;
  padding: 20px 0 10px 0;
  color: black;
}

.wrap-options {
  max-width: 1250px;
  min-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  /*margin-left: calc(20%);*/
  /*margin-right: calc(20%);*/
  padding-left: 60px;
}

.pad-0 {
  padding: 0;
  margin-bottom: 40px;
}

.main_banner {
  max-width: 1800px;
  padding: 50px 10px;
  background: rgba(107, 185, 177, 0.07);
  border-radius: 50px;
  margin: -10px auto 50px;
  display: flex;
  justify-content: flex-start;
  flex: 1 1 auto;
}


.banner_wrap-add {
  justify-self: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  min-width: 1400px;
  padding: 0 10px;
}

.banner_wrap {
  justify-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  min-width: 100%;
}

.banner_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 450px;
  margin-right: 30px;
  /*margin-left: -30px;*/
}

.banner_text > h1 {
  font-size: 66px;
  font-weight: 700;
  line-height: 70px;
  color: rgba(17, 17, 17, 1);
  margin-bottom: 20px;
}

.banner_text > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: rgba(76, 76, 76, 1);
  margin-bottom: 40px;
}

.banner_text button {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: white;
  border-radius: 10px;
  width: 160px;
  height: 50px;
  background: rgba(107, 185, 177, 1);
  letter-spacing: 1px;
}

.banner_img {
  max-width: 535px;
  max-height: 420px;
}

.categories_h1 {
  color: #111111;
  line-height: 70px;
  font-weight: 700;
  font-size: 36px;
}

.categories_horList_h1 {
  color: #111111;
  line-height: 40px;
  font-weight: 700;
  font-size: 36px;
}

.categories_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 1400px;
  justify-self: flex-start;
  /*margin-left: calc(13.5%);*/
  /*margin-right: calc(13.5%);*/
  /*margin-bottom: 20px;*/
  /*margin-top: 80px;*/
}

.horLis_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 1400px;
  justify-self: flex-start;
  /*margin-left: calc(13.5%);*/
  /*margin-right: calc(13.5%);*/
  /*margin-bottom: 20px;*/
  /*margin-top: 80px;*/
}

.places_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1520px;
  justify-self: flex-start;
  flex-wrap: wrap;
}

.places_wrap-big {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1510px;
  justify-self: flex-start;
  flex-wrap: wrap;
}

.places_add_wrap {
  padding-left: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1530px;
  justify-self: flex-start;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  min-width: 79.15%;
}

.back_main_add_wrap {
  padding-left: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1530px;
  justify-self: flex-start;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  /*flex-direction: column;*/
  min-width: 79.15%;
}

.places_add_wrap-big {
  padding-left: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1520px;
  justify-self: flex-start;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  min-width: 79.15%;
}

.category_add_wrap {
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1520px;
  justify-self: flex-start;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  min-width: 79.15%;
}

.horlist_add_wrap {
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1520px;
  justify-self: flex-start;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  min-width: 79.15%;
}

.places_header_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1520px;
  justify-self: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}

.back_main_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1520px;
  justify-self: flex-start;
  flex-wrap: wrap;
}

.places_cont {
  max-width: 1920px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  justify-self: flex-start;
  margin: 80px auto 20px;
}

.places_cont_profile {
  max-width: 1920px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  justify-self: flex-start;
  margin: 40px 0 20px;
}

.back_main_container {
  max-width: 1920px;
  display: flex;
  justify-content: center;
  padding: 0;
  justify-self: flex-start;
  margin: 20px 0;
}

.wrap-header-horList {
  display: flex;
  justify-self: flex-start;
  align-items: center;
}

.profile-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100%);
  flex: 1 1 auto;
  margin-top: 50px;
}

.profile-inputs .profile-inputs-column:first-of-type {
  margin-right: 40px;
}

.profile-inputs .profile-inputs-column:last-of-type {
  margin-left: auto;
  margin-right: 0;
  align-items: flex-end;
}

.profile-inputs-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 auto;
  height: 140px;
}

.add_pad {
  padding-bottom: 20px;
}

.profile-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  height: 46px;
}

.profile-input p {
  /*margin-right: 40px;*/
  font-size: 13px;
  line-height: 18px;
  color: #455A64;
  font-weight: 700;
  min-width: 80px;
}

.profile-input input {
  height: 46px;
  font-size: 13px;
  line-height: 18px;
  color: #455A64;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid rgba(175, 204, 201, 0.26);
  background: white;
  padding: 14px 10px;
  width: 350px;
  /*min-width: calc(40%);*/
}

.profile-input-password {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile-input-password input {
  letter-spacing: 2px;
}

.profile-change-pass-button {
  margin-top: 14px;
  background: white;
  width: 120px;
  color: #6BB9B1;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 75px;
}

.profile_list_pad {
  padding: 0;
}

@media (max-width: 1920px) {
  .main_banner {
    margin-left: 60px;
    margin-right: 60px;
  }
  .header_cont {
    margin: 0;
  }
  .place_cont {
    margin: 0;
  }
}

@media (max-width: 1650px ) {
  .slider-max-w {
    max-width: 960px;
  }

}

@media (max-width: 1600px) {
  .wrap-web-profile {
    max-width: 1200px;
    min-width: 70.5%;
  }
}

@media (max-width: 1580px) {
  .banner_wrap-add {
    min-width: calc(100%);
    padding: 0 10px;
  }
}

@media (max-width: 1530px) {
  .places_wrap {
    width: 1240px;
  }
  .places_wrap-big {
    width: 1230px;
  }
  .places_add_wrap {
    margin-left: unset;
  }
  .places_add_wrap-big {
    margin-left: unset;
  }
}

@media (max-width: 1500px) {
  .category_add_wrap {
    max-width: 1320px;
    margin-left: unset;
  }
  .categories_wrap {
    max-width: 1200px;
  }
  .slider-wrap-categories {
    max-width: 1200px;
  }
  .slider-categories {
    max-width: 1200px;
  }
  .slider-horList1 {
    max-width: 1160px;
  }
  .slider-horList2 {
    max-width: 1200px;
  }
  .horlist_add_wrap {
    margin-left: unset;
    max-width: 1320px;
  }
}

@media (max-width: 1450px) {
  .slider-max-w {
    max-width: 760px;
  }

  /*.slider-horList2 {*/
  /*  max-width: 960px;*/
  /*}*/

}

@media (max-width: 1400px) {
  .change_search {
    width: 300px !important;
  }
  .form-search-web {
    margin-left: 60px;
  }
}

@media (max-width: 1300px) {
  .category_add_wrap {
    max-width: 1200px;
    margin-left: unset;
  }
  .categories_wrap {
    max-width: 1080px;
  }
  .slider-wrap-categories {
    max-width: 1080px;
  }
  .slider-categories {
    max-width: 1080px;
  }
  .slider-horList1 {
    max-width: 870px;
  }
  .slider-horList2 {
    max-width: 960px;
  }
  .horlist_add_wrap {
    margin-left: unset;
    max-width: 1080px;
  }
}

@media (max-width: 1250px) {
  .places_wrap {
    width: 950px;
  }
  .slider-prop {
    max-width: 956px;
  }
  .slider-prop > .slick-prev {
    top: -30px;
    left: calc(100% - 90px)
  }

  .slider-prop > .slick-next {
    top: -30px;
    right: 20px;
  }
  .favorite_pos {
    position: relative;
    right: 40px;
    align-self: flex-end;
  }
  .selectModal {
    width: 250px;
  }
}


@media (max-width: 1200px) {
  .slider-categories {
    max-width: 900px;
  }
  .category_add_wrap {
    max-width: 1020px;
    margin-left: unset;
  }
  .categories_wrap {
    max-width: 900px;
  }
  .slider-wrap-categories {
    max-width: 900px;
  }
}

@media (max-width: 1220px) {
  .slider-horListProfile {
    max-width: 720px;
  }
}

@media (max-width: 1150px) {
  .slider-max-w {
    max-width: 560px;
  }
  .places_wrap-big {
    width: 790px;
  }
  .wrap-detail-block {
    min-width: 45%;
    flex-basis: auto;
    margin-right: calc(3%);
    margin-bottom: 40px;
    align-self: flex-start;
    justify-self: center;
  }

}

@media (max-width: 1050px) {
  .slider-horList1 {
    max-width: 580px;
  }
  .slider-horList2 {
    max-width: 720px;
  }
  .horlist_add_wrap {
    margin-left: unset;
    max-width: 840px;
  }
  .slider-prop {
    max-width: 757px;
  }
  .profile-inputs-column {
    flex-basis: 100%;
  }
  .profile-inputs .profile-inputs-column:last-of-type {
    margin-left: 0;
    margin-right: 40px;
    align-items: flex-start;
  }
  .profile-form-wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 1000px) {
  .slider-categories {
    max-width: 720px;
  }
  .category_add_wrap {
    max-width: 840px;
    margin-left: unset;
  }
  .categories_wrap {
    max-width: 720px;
  }
  .slider-wrap-categories {
    max-width: 720px;
  }
  .selectModal {
    width: 200px;
  }
  .map_button {
    width: 150px;
  }
}

@media (max-width: 960px) {
  .places_wrap {
    width: 660px;
  }
}

@media (max-width: 820px) {
  .slider-horListProfile {
    max-width: 360px;
  }
  .slider-horListProfile > .slick-prev {
    top: -50px;
    left: calc(100% - 110px)
  }

  .slider-horListProfile > .slick-next {
    top: -50px;
    right: 40px;
  }
  .wrap-web-profile div.places_cont_profile:nth-of-type(3) {
    margin-top: 70px;
  }
}

@media (max-width: 850px) {
  .slider-prop {
    max-width: 360px;
  }
}

@media (max-width: 800px) {
  .places_wrap-big {
    width: 430px;
  }
}

@media (max-width: 670px) {
  .places_wrap {
    width: 370px;
  }
}
