.termsModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffcc;
  z-index: 100;
  padding: 12%;
}

.termsModal__wrapper {
  padding: 10px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border: solid 1px #00000049;
}


.termsModal__content {
  background-color: #ffffff;
  margin: 15px 0;
  width: 100%;
  height: 80%;
  overflow: auto;
}
